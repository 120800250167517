export function objectToFormData(obj: any, form?: FormData, namespace?: string): FormData {
  const formData = form || new FormData()

  for (let property in obj) {
    if (!obj.hasOwnProperty(property)) {
      continue
    }
    const formKey = namespace ? `${namespace}[${property}]` : property
    if (obj[property] instanceof Date) {
      formData.append(formKey, obj[property].toISOString())
    } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      
        // Handle nested objects
      objectToFormData(obj[property], formData, formKey)
    } else {
      formData.append(formKey, obj[property])
    }
  }

  return formData
}
