import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import PublicPolicyPage from '../modules/publicPolicy/PublicPolicyPage'

const PrivateRoutes = () => {
  const AdministrationPage = lazy(() => import('../modules/administration/AdministrationPage'))
  const PeiPage = lazy(() => import('../modules/pei/PeiPage'))
  const MDPPage = lazy(() => import('../modules/municipalDevelopmentPlan/MDPPage'))
  const PeoPage = lazy(() => import('../modules/pao/PaoPage'))
  const ReportSinitPage = lazy(() => import('../modules/reports/sinit/ReportSinitPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        {/* <Route path='administration/security/users' element={<Users />} />
        <Route path='administration/security/roles' element={<Roles />} />
        <Route path='administration/security/permissions' element={<Permissions />} />
        <Route
          path='administration/indicadores/periodos-planificacion'
          element={<PeriodosPlanificacion />}
        />
        <Route path='administration/indicadores/instituciones' element={<Instituciones />} />
        <Route path='administration/indicadores/departamentos' element={<Departamentos />} />
        <Route path='administration/indicadores/municipios' element={<Municipios />} />
        <Route path='administration/indicadores/unidades-medida' element={<UnidadesMedida />} /> */}

        <Route
          path='administration/*'
          element={
            <SuspensedView>
              <AdministrationPage />
            </SuspensedView>
          }
        />

        <Route
          path='pei/*'
          element={
            <SuspensedView>
              <PeiPage />
            </SuspensedView>
          }
        />

        <Route
          path='pao/*'
          element={
            <SuspensedView>
              <PeoPage />
            </SuspensedView>
          }
        />

        <Route
          path='plan-desarrollo-municipal/*'
          element={
            <SuspensedView>
              <MDPPage />
            </SuspensedView>
          }
        />
        <Route
          path='politicas-publicas/*'
          element={
            <SuspensedView>
              <PublicPolicyPage />
            </SuspensedView>
          }
        />
        <Route
          path='reportes/sinit/*'
          element={
            <SuspensedView>
              <ReportSinitPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
