import axios from "axios";
import { Cabinet, PublicPolicyData, PublicPolicyReport, PublicPolicyState, PublicPolicyType } from "./_models";
import { objectToFormData } from "../../shared/utils/Util";
const API_URL = process.env.REACT_APP_API_URL

const ALL_CABINETS_URL: string = `${API_URL}/public-policies/all-cabinets`;
const ALL_POLICY_TYPES_URL: string = `${API_URL}/public-policies/all-types`;
const ALL_POLICY_STATES_URL: string = `${API_URL}/public-policies/all-states`;
const CREATE_PUBLIC_POLICY_URL: string = `${API_URL}/public-policies/store`;
const GET_PUBLIC_POLICY_URL: string = `${API_URL}/public-policies/index`;
const UPDATE_PUBLIC_POLICY_URL: string = `${API_URL}/public-policies/update`;
const DELETE_PUBLIC_POLICY_URL: string = `${API_URL}/public-policies/delete`;
const REPORT_PUBLIC_POLICY_URL: string = `${API_URL}/public-policies/report`;
const DOCUMENT_FILE_PUBLIC_POLICY_URL: string = `${API_URL}/public-policies/get-file`;

export function getAllCabinets() {
    return axios.get<Cabinet>(ALL_CABINETS_URL);
}
export function getAllTypes() {
    return axios.get<PublicPolicyType>(ALL_POLICY_TYPES_URL);
}
export function getAllStates() {
    return axios.get<PublicPolicyState>(ALL_POLICY_STATES_URL);
}

export function createPublicPolicy(data: PublicPolicyData) {
    return axios.post(CREATE_PUBLIC_POLICY_URL, objectToFormData(data));
}
export function getPublicPolicies(params: any) {
    return axios.get<{ data: Array<PublicPolicyData>, total: number }>(GET_PUBLIC_POLICY_URL, { params }).then(response => response.data);
}
export function updatePublicPolicy(data: PublicPolicyData) {
    return axios.post(UPDATE_PUBLIC_POLICY_URL, objectToFormData(data));
}
export function deletePublicPolicy(register_id: number) {
    return axios.delete<PublicPolicyData>(DELETE_PUBLIC_POLICY_URL + "/" + register_id)
}

export function getReport(params: any) {
    return axios.get<{ data: any; columns: Array<string> }>(REPORT_PUBLIC_POLICY_URL, { params }).then(response => response.data);
}

export const downloadDocumentFile = async (id: number) => (
    axios.get(
        `${DOCUMENT_FILE_PUBLIC_POLICY_URL}/${id}`,
        { responseType: 'blob' }
    )
)