import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import {useFormik} from 'formik'
import React, {useState, useEffect, useRef} from 'react'
import {
  InstitutionModel,
  InstitutionsModel,
} from 'src/app/modules/administration/configuration/_models'
import {getUserInstitutions} from '../../../administration/configuration/_requests'
import * as Yup from 'yup'
import {
  createPublicPolicy,
  downloadDocumentFile,
  getAllCabinets,
  getAllStates,
  getAllTypes,
  updatePublicPolicy,
} from '../_requests'
import {Cabinet, PublicPolicyState, PublicPolicyType} from '../_models'
import {DownloadRounded, UploadFile} from '@mui/icons-material'

interface RegisterModalProps {
  isOpen: boolean
  selectedRegister: any | null
  fetchRegisters: () => void
  onClose: () => void
  openSnackbar: (message: string, type: 'success' | 'error' | 'info' | 'warning') => void
}
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const apiGetInstitutions = async (): Promise<InstitutionModel[]> => {
  try {
    const response = await getUserInstitutions(false)
    if (Array.isArray(response.data)) {
      const institutionsData: InstitutionsModel = {institutions: response.data}
      return institutionsData.institutions
    } else {
      console.error('Expected an array but received:', response.data)
      return []
    }
  } catch (error) {
    console.error('Error fetching institutions', error)
    return []
  }
}

const RegisterModal: React.FC<RegisterModalProps> = ({
  isOpen,
  onClose,
  openSnackbar,
  selectedRegister,
  fetchRegisters,
}) => {
  const [institutions, setInstitutions] = useState<InstitutionModel[]>([])
  const [cabinets, setCabinets] = useState<Cabinet[]>([])
  const [policyTypes, setPolicyTypes] = useState<PublicPolicyType[]>([])
  const [policyStates, setPolicyStates] = useState<PublicPolicyState[]>([])
  const [, setIsLoading] = useState<boolean>(true)

  const fileInputRef = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [selectedFileName, setSelectedFileName] = useState<string>('')

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
      setSelectedFileName(event.target.files[0].name)
    }
  }
  const handleUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
    return
  }

  const handleDownload = async () => {
    setIsLoading(true)
    downloadDocumentFile(selectedRegister.id)
      .then((resp) => {
        console.log(resp.headers['File_name'])
        let blob = new Blob([resp.data])
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', selectedRegister.document_file)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) => openSnackbar(error.response?.data?.message, 'error'))
      .finally(() => setIsLoading(false))
  }

  const getValidationSchema = (selectedRegister) => {
    // Base schema without password validation
    let validationSchema = Yup.object().shape({
      name: Yup.string().required('El nombre del permiso es obligatorio'),
      institution_id: Yup.number()
        .min(1, 'Seleccione una institución')
        .required('Institución es obligatoria'),
      cabinet_id: Yup.number()
        .min(1, 'Seleccione un gabinete')
        .required('El gabinete o sector es obligatorio'),
      policy_type_id: Yup.number()
        .min(1, 'Seleccione un tipo de politica')
        .required('El tipo de política es obligatorio'),
      cost: Yup.number()
        .min(0, 'Ingrese un costo')
        .required('El costo de la política es obligatorio'),
      policy_state_id: Yup.number()
        .min(1, 'Seleccione el estado de la politica')
        .required('El estado de la política es obligatorio'),
    })

    return validationSchema
  }
  const formik = useFormik({
    initialValues: selectedRegister ?? {
      id: null,
      name: '',
      institution_id: 0,
      cabinet_id: 0,
      policy_type_id: 0,
      cost: 0,
      policy_state_id: 0,
    },
    enableReinitialize: true,
    validationSchema: getValidationSchema(selectedRegister),
    onSubmit: async (values) => {
      try {
        values.file = selectedFile
        if (values.id) {
          await updatePublicPolicy(values)
          openSnackbar('Registro actualizado con éxito', 'success')
        } else {
          await createPublicPolicy(values)
          openSnackbar('Registro agregado con éxito', 'success')
        }
        fetchRegisters()
        onClose()
      } catch (error) {
        openSnackbar('Ha ocurrido un error', 'error')
      }
    },
  })

  useEffect(() => {
    formik.resetForm()

    const fetchInstitutions = async () => {
      apiGetInstitutions().then((institutions) => {
        setInstitutions(institutions)
        if (institutions.length > 0) {
          formik.setFieldValue('institution_id', institutions[0].id)
        }
      })
    }
    const fetchCabinets = async () => {
      try {
        const response = await getAllCabinets()
        if (Array.isArray(response.data)) {
          setCabinets(response.data)
        } else {
          console.error('Expected an array but received:', response.data)
          setCabinets([])
        }
      } catch (error) {
        console.error('Error fetching data', error)
        setCabinets([])
      } finally {
        setIsLoading(false)
      }
    }
    const fetchPolicyTypes = async () => {
      try {
        const response = await getAllTypes()
        if (Array.isArray(response.data)) {
          setPolicyTypes(response.data)
        } else {
          console.error('Expected an array but received:', response.data)
          setPolicyTypes([])
        }
      } catch (error) {
        console.error('Error fetching data', error)
        setPolicyTypes([])
      } finally {
        setIsLoading(false)
      }
    }
    const fetchPolicyStates = async () => {
      try {
        const response = await getAllStates()
        if (Array.isArray(response.data)) {
          setPolicyStates(response.data)
        } else {
          console.error('Expected an array but received:', response.data)
          setPolicyStates([])
        }
      } catch (error) {
        console.error('Error fetching data', error)
        setPolicyStates([])
      } finally {
        setIsLoading(false)
      }
    }

    if (isOpen) {
      setIsLoading(true)
      fetchInstitutions()
      fetchCabinets()
      fetchPolicyTypes()
      fetchPolicyStates()
      setSelectedFile(null)
      setSelectedFileName('')
    }
  }, [isOpen])
  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby='register-modal-title'>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: 'background.paper',
          boxShadow: 24,
          maxHeight: 'calc(100vh - 1rem)',
          overflow: 'scroll',

          px: 4,
          pb: 3,
          pt: 2,
        }}
      >
        <Box sx={{display: 'flex', justifyContent: 'left'}}>
          <Typography
            id='register-modal-title'
            variant='h4'
            component='h4'
            color={'primary'}
            fontWeight={'bold'}
            fontSize={'1.5em'}
          >
            {formik.values.id ? 'Editar' : 'Crear'} Politica publica
          </Typography>
        </Box>

        <Divider />

        {/* Form Section */}
        <Box component='form' onSubmit={formik.handleSubmit} sx={{overflow: 'scroll'}}>
          <Box sx={{mt: 2, display: 'flex', flexDirection: 'column', gap: 2}}>
            <TextField
              fullWidth
              label='Politica publica'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              // helperText={formik.touched.name && formik.errors.name}
              autoComplete='off'
            />
            <FormControl
              fullWidth
              sx={{mt: 2}}
              error={formik.touched.cabinet_id && Boolean(formik.errors.cabinet_id)}
            >
              <InputLabel id='cabinet_id-select-label'>Gabinete o sector</InputLabel>
              <Select
                labelId='cabinet_id-select-label'
                id='cabinet_id-select'
                name='cabinet_id'
                value={formik.values.cabinet_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label='Gabinete o Sector'
              >
                {cabinets.length > 0 ? (
                  cabinets.map((item) => {
                    const value = item.id !== null ? item.id : 0
                    return (
                      <MenuItem key={item.id} value={value}>
                        {item.name}
                      </MenuItem>
                    )
                  })
                ) : (
                  <MenuItem value='' disabled>
                    Sin opciones disponibles
                  </MenuItem>
                )}
              </Select>
              {formik.touched.cabinet_id && formik.errors.cabinet_id && (
                <Typography variant='caption' color='error'>
                  {/* {formik.errors.institution_id} */}
                </Typography>
              )}
            </FormControl>

            {institutions.length > 1 ? (
              <FormControl
                fullWidth
                sx={{mt: 2}}
                error={formik.touched.institution_id && Boolean(formik.errors.institution_id)}
              >
                <InputLabel id='institution-select-label'>Institución</InputLabel>
                <Select
                  labelId='institution-select-label'
                  id='institution-select'
                  name='institution_id'
                  value={formik.values.institution_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label='Institución'
                >
                  {institutions.length > 0 ? (
                    institutions.map((institution) => {
                      const value = institution.id !== null ? institution.id : 0
                      return (
                        <MenuItem key={institution.id} value={value}>
                          {institution.name}
                        </MenuItem>
                      )
                    })
                  ) : (
                    <MenuItem value='' disabled>
                      Sin opciones disponibles
                    </MenuItem>
                  )}
                </Select>
                {formik.touched.institution_id && formik.errors.institution_id && (
                  <Typography variant='caption' color='error'>
                    {/* {formik.errors.institution_id} */}
                  </Typography>
                )}
              </FormControl>
            ) : (
              <Typography variant='h5' component='h5' fontSize={'1.50em'}>
                <div className='d-inline-block fw-bold'>Institución:&nbsp;</div>
                <div className='d-inline-block text-gray'>{institutions[0]?.name}</div>
              </Typography>
            )}

            <FormControl
              fullWidth
              sx={{mt: 2}}
              error={formik.touched.policy_type_id && Boolean(formik.errors.policy_type_id)}
            >
              <InputLabel id='policy_type-select-label'>Tipo de política</InputLabel>
              <Select
                labelId='policy_type-select-label'
                id='policy_type-select'
                name='policy_type_id'
                value={formik.values.policy_type_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label='Tipo de politica'
              >
                {policyTypes.length > 0 ? (
                  policyTypes.map((item) => {
                    const value = item.id !== null ? item.id : 0
                    return (
                      <MenuItem key={item.id} value={value}>
                        {item.name}
                      </MenuItem>
                    )
                  })
                ) : (
                  <MenuItem value='' disabled>
                    Sin opciones disponibles
                  </MenuItem>
                )}
              </Select>
              {formik.touched.policy_type_id && formik.errors.policy_type_id && (
                <Typography variant='caption' color='error'>
                  {/* {formik.errors.institution_id} */}
                </Typography>
              )}
            </FormControl>
            <TextField
              fullWidth
              label='Costo'
              variant='outlined'
              name='cost'
              type='number'
              value={formik.values.cost}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputProps={{
                startAdornment: <InputAdornment position='start'>L</InputAdornment>,
              }}
              error={formik.touched.name && Boolean(formik.errors.name)}
              // helperText={formik.touched.name && formik.errors.name}
              autoComplete='off'
            />

            <FormControl
              fullWidth
              sx={{mt: 2}}
              error={formik.touched.policy_state_id && Boolean(formik.errors.policy_state_id)}
            >
              <InputLabel id='policy_state-select-label'>Estado de política</InputLabel>
              <Select
                labelId='policy_state-select-label'
                id='policy_state-select'
                name='policy_state_id'
                value={formik.values.policy_state_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label='Estado de politica'
              >
                {policyStates.length > 0 ? (
                  policyStates.map((item) => {
                    const value = item.id !== null ? item.id : 0
                    return (
                      <MenuItem key={item.id} value={value}>
                        {item.name}
                      </MenuItem>
                    )
                  })
                ) : (
                  <MenuItem value='' disabled>
                    Sin opciones disponibles
                  </MenuItem>
                )}
              </Select>
              {formik.touched.policy_state_id && formik.errors.policy_statetype_id && (
                <Typography variant='caption' color='error'>
                  {/* {formik.errors.institution_id} */}
                </Typography>
              )}
            </FormControl>
            {selectedRegister && (
              <Button
                disabled={!selectedRegister?.document_file}
                variant='contained'
                startIcon={<DownloadRounded />}
                color='secondary'
                onClick={handleDownload}
                children='Descargar Archivo'
              />
            )}
            <VisuallyHiddenInput
              type='file'
              ref={fileInputRef}
              onChange={handleFileChange}
              accept='.xlsx,.docx,.pdf'
            />
            <Box display='flex' alignItems='center' justifyContent='space-between' gap={2}>
              {selectedFileName && (
                <Typography variant='h6' component='h6'>
                  <span className='fw-bold'>Archivo Cargado</span>: {selectedFileName}
                </Typography>
              )}
              <Button
                variant='contained'
                startIcon={selectedFile ? <></> : <UploadFile />}
                color={selectedFileName ? 'secondary' : 'primary'}
                onClick={handleUpload}
                title={
                  selectedFile
                    ? 'Cambiar el archivo a cargar'
                    : 'Seleccionar/Subir un nuevo archivo para cargar'
                }
              >
                {selectedFile ? <UploadFile /> : 'Seleccionar Archivo a Cargar'}
              </Button>
            </Box>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2}}>
            <Button onClick={onClose} color='secondary' variant='outlined'>
              Cancelar
            </Button>
            <Button type='submit' variant='contained' color='primary'>
              Guardar
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default RegisterModal
